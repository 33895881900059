import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { StyledLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";
import { getLanguage } from "./LoginPage";
import { StyledA } from "./styled";

const FooterContainer = styled.div`
  display: flex;
  //height: 100%;
  background-color: ${(props) => props.theme.pageBackground};
  display: flex;
  flex-flow: column;
  //flex: 1 1 auto;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const PageFooter = styled.div`
  display: flex;
  justify-content: center;
  max-width: 690px;
  //margin-top: 5px;
  //margin: 0 auto;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  //height: 100vh;
  flex: 1 1 auto;

  .conditions-text {
    font-size: small;

    a {
      color: gray;
      text-decoration: none;
    }
  }
`;

const LoginFooter = (props: { page: string }) => {
  const { page } = props;
  const { t, i18n } = useTranslation();
  return (
    <FooterContainer>
      <PageFooter>
        <FlexCol padding={5} rowGap={10} height="100%">
          <FlexRow justifyContent="center" colGap={10}>
            {i18n.options.supportedLngs &&
              i18n.options.supportedLngs
                ?.map((s: string) => s)
                .sort((a: string, b: string) => {
                  const langA = t(getLanguage(a) ?? "") ?? "";
                  const langB = t(getLanguage(b) ?? "") ?? "";
                  return langA < langB ? -1 : 1;
                })
                .map((lang) => {
                  if (!lang || lang === "cimode" || !getLanguage(lang)) {
                    return null;
                  }

                  return (
                    <StyledA key={lang} href={"/" + page + "/" + lang}>
                      <Text size={12}>{t(getLanguage(lang) ?? lang)}</Text>
                    </StyledA>
                  );
                })}
          </FlexRow>
          <FlexRow justifyContent="center">
            <StyledA href={"/video-dating/" + i18n.language}>
              <Text size={14}>{t("Video Dating")}</Text>
            </StyledA>
            {" · "}
            <StyledA href={"/international-dating/" + i18n.language}>
              <Text size={14}>{t("International Dating")}</Text>
            </StyledA>
            {" · "}
            <StyledA href={"/find-asian-women/" + i18n.language}>
              <Text size={14}>{t("Find Asian Women")}</Text>
            </StyledA>
            {" · "}
            <StyledA href={"/single-women-dating/" + i18n.language}>
              <Text size={14}>{t("Single Women Dating")}</Text>
            </StyledA>
            {" · "}
            <StyledA href={"/single-men-dating/" + i18n.language}>
              <Text size={14}>{t("Single Men Dating")}</Text>
            </StyledA>
          </FlexRow>
          <FlexRow justifyContent="center">
            <StyledA href={"https://affiliate.minglify.io"}>
              <Text size={14}>
                {t("Start earning with the Minglify Affiliate Program")}
              </Text>
            </StyledA>
          </FlexRow>
          <FlexRow justifyContent="center">
            <Text size={12}>
              {t("By signing up, you agree to our")}{" "}
              <StyledLink to="/help-center/terms">
                {t("Terms and Conditions")}
              </StyledLink>
              . {t("Learn how we use your data in our")}{" "}
              <StyledLink to="/help-center/privacy">
                {t("Privacy Policy")}
              </StyledLink>
              . {t("Read about cookies in our")}{" "}
              <StyledLink to="/help-center/cookies">
                {t("Cookie policy")}
              </StyledLink>
              . V.1.0.0.4 · Pied Apps AB © 2024
            </Text>
          </FlexRow>
          <FlexRow justifyContent="center">
            <Box sx={{ width: 10, height: 50 }} />
          </FlexRow>
        </FlexCol>
      </PageFooter>
    </FooterContainer>
  );
};
export default LoginFooter;
