import { useMutation, useQuery } from "@apollo/client";
import {
  CurrencyDollarIcon,
  DevicePhoneMobileIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
import { useTheme } from "@mui/material";
import OptionMenu, { MenuOption } from "components/OptionMenu/OptionMenu";
import { StyledSpinner } from "components/Reels/styled";
import { Col, FlexRow, Row } from "components/Styled/Container";
import { StyledLink } from "components/Styled/StyledLink";
import { StyledText } from "components/Styled/Text";
import { AFFILIATE_INSTALL_CLICK } from "graphql/mutations-gql";
import { VALID_AFFILIATE_LINK } from "graphql/queries-gql";
import { useDismissableSnackbar } from "hooks/useDismissableSnackbar";
import { getLanguage } from "pages/login/LoginPage";
import { useCallback, useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { logger } from "util/log";
import {
  InfoHeader,
  InfoHeaderWrapper,
  InfoItem,
  PageContainer,
  ScreensContainer,
  ScreensImage,
  StoreImage,
  TopContainer,
  TopLogo,
  TopLogoText,
} from "./styled";

const FlagImage = styled.img`
  width: 20px;
  height: 20px;
`;

const FlagIcon: React.FC<{ countryCode: string }> = ({ countryCode }) => {
  return <FlagImage src={`/images/countryflags/${countryCode}.svg`} />;
};

const AppInstall = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const referer = path.replace("/app-install/", "");
  const [validAffiliate, setValidAffiliate] = useState(false);
  const [signupCreditsBonus, setSignupCreditsBonus] = useState(0);
  const { showSnackbar } = useDismissableSnackbar();
  const { loading } = useQuery(VALID_AFFILIATE_LINK, {
    context: { service: "api-auth" },
    variables: {
      affiliateLink: referer,
    },
    onCompleted: (data) => {
      logger.log("validAffiliateLink", JSON.stringify(data));
      setValidAffiliate(data.validAffiliateLink.valid);
      setSignupCreditsBonus(data.validAffiliateLink.signupCreditsBonus);
      setAffiliateFetched(true);
    },
    onError: (error) => {
      logger.error(error);
      setAffiliateFetched(true);
    },
  });
  const [affiliateInstallClick] = useMutation(AFFILIATE_INSTALL_CLICK);
  const [affiliateFetched, setAffiliateFetched] = useState(false);
  const [clicked, setClicked] = useState(false);
  const theme = useTheme();

  const getCurrentLangText = (lang: string) => {
    if (i18n.language === lang) {
      return " (" + t("current") + ")";
    }

    return "";
  };

  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const getLanguageOptions = (): MenuOption[] => {
    const options =
      i18n.options.supportedLngs &&
      i18n.options.supportedLngs
        ?.map((s: string) => s)
        .sort((a: string, b: string) => {
          const langA = t(getLanguage(a) ?? "") ?? "";
          const langB = t(getLanguage(b) ?? "") ?? "";
          return langA < langB ? -1 : 1;
        })
        .map((lang) => {
          if (!lang || lang === "cimode" || !getLanguage(lang)) {
            return null;
          }
          const option: MenuOption = {
            text: t(getLanguage(lang) ?? "") + getCurrentLangText(lang),
            dividerAfter: false,
            onClick: () => setLanguage(lang),
            icon: <FlagIcon countryCode={lang} />,
          };
          return option;
        });
    if (!options) {
      return [];
    }
    return options.filter((o) => o !== null) as MenuOption[];
  };

  const appStoreClick = useCallback(
    (platform?: string) => {
      console.log("appStoreClick");
      if (!loading && affiliateFetched && validAffiliate && !clicked) {
        setClicked(true);
        affiliateInstallClick({
          context: { service: "api-auth" },
          variables: {
            affiliateLink: referer,
          },
          onCompleted: (data) => {
            logger.log("affiliateInstallClick", JSON.stringify(data));

            if (isIOS) {
              window.location.href = "http://appstore.com/minglify";
            } else if (isAndroid) {
              window.location.href =
                "http://play.google.com/store/apps/details?id=se.minglify.app";
            } else {
              if (platform === "google") {
                window.location.href =
                  "http://play.google.com/store/apps/details?id=se.minglify.app";
              } else {
                window.location.href =
                  "https://apps.apple.com/us/app/social-dating-chat-minglify/id6447954430?platform=iphone";
              }
            }
          },
          onError: (error) => {
            showSnackbar(t("Failed to register affiliate install"), "error");
          },
        });
      } else {
        // Simply open the app store link. The affiliate is not valid.
        if (isIOS) {
          window.location.href = "http://appstore.com/minglify";
        } else if (isAndroid) {
          window.location.href =
            "http://play.google.com/store/apps/details?id=se.minglify.app";
        }
      }
    },
    [
      affiliateInstallClick,
      referer,
      showSnackbar,
      t,
      validAffiliate,
      clicked,
      loading,
      affiliateFetched,
      setClicked,
    ]
  );

  const getLinkIcon = () => {
    if (loading) {
      return <StyledSpinner />;
    }
    return <CurrencyDollarIcon width={40} color={theme.colors.primary} />;
  };

  // If we are on an iOS or android device, we will perform the affiliate link click automatically and
  // open up app store or google play immediately.
  useEffect(() => {
    if (
      !loading &&
      (isIOS || isAndroid) &&
      affiliateFetched &&
      validAffiliate &&
      !clicked
    ) {
      appStoreClick();
    }
  }, [affiliateFetched, appStoreClick, loading, validAffiliate, clicked]);

  return (
    <div style={{ height: "100vh" }}>
      <PageContainer>
        <TopContainer fixedBg={isIOS || isAndroid ? false : true}>
          <Col maxWidth={800}>
            <Row wrap="nowrap" width="100%" justifyContent="center">
              <TopLogo src="/icon256.png" />
              <TopLogoText src="/logo-text-white.png" />
            </Row>
            <FlexRow justifyContent="center" colGap={20}>
              <StyledText
                size={28}
                weight={900}
                shadow="#000"
                color={theme.colors.primary}
              >
                {t("Get the app now!")}
              </StyledText>
              <OptionMenu
                type="CircIconButton"
                id="langdialog-options"
                icon={<FlagIcon countryCode={i18n.language} />}
                placement="top-start"
                options={getLanguageOptions()}
              />
            </FlexRow>

            <Row justifyContent="center" alignItems="center">
              <StyledLink to="" onClick={() => appStoreClick("apple")}>
                <StoreImage src="/appstore.png" alt="appstore" />
              </StyledLink>
              <StyledLink to="" onClick={() => appStoreClick("google")}>
                <StoreImage src="/googleplay.png" alt="googleplay" />
              </StyledLink>
            </Row>
          </Col>
          <ScreensContainer>
            <ScreensImage src="/images/homescreens.png" />
          </ScreensContainer>
        </TopContainer>
        <InfoHeaderWrapper>
          <InfoHeader>
            <InfoItem
              title={t("Affiliate link")}
              text={
                validAffiliate
                  ? t("This is an affiliate link for:") + " " + referer
                  : t("This is not a valid affiliate link.")
              }
              icon={<LinkIcon width={40} color={theme.colors.primary} />}
            />
            <InfoItem
              title={t("Free credits")}
              text={
                t(
                  "Installing and signing up with this affiliate link will give you"
                ) +
                " " +
                signupCreditsBonus +
                " " +
                t("free credits") +
                "."
              }
              icon={getLinkIcon()}
            />
            <InfoItem
              title={t("Sign up now!")}
              text={t(
                "Press on the download buttons above to install the app and sign up."
              )}
              icon={
                <DevicePhoneMobileIcon
                  width={40}
                  color={theme.colors.primary}
                />
              }
            />
          </InfoHeader>
        </InfoHeaderWrapper>
      </PageContainer>
    </div>
  );
};
export default AppInstall;
