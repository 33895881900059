import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../hooks/useAuth";
import { lazy } from "react";

const LoginPage = lazy(() => import("../../pages/login/LoginPage"));

export function RequireAuth({ children }: { children: JSX.Element }) {
  const authenticated = useAuthStore.use.authenticated();
  const location = useLocation();

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    //return <Navigate to="/login" state={{ from: location }} replace />;

    if (location && (location.pathname === "/" || location.pathname === "")) {
      return <LoginPage />;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  // Remove attributes set at login page for darkmodes
  document.documentElement.removeAttribute("loginPageLight");
  document.documentElement.removeAttribute("loginPageDark");

  return children;
}
